import { BASE_URL } from "#next-seo.config";
import { NextSeo } from "next-seo";
import { useState } from "react";
import BlogPost from "~/components/BlogPost";
import Container from "~/components/Container";
import { getAllFilesFrontMatter } from "~/lib/mdx";

// seo
export const relativeUrl: string = "/inner-circle";
export const url: string = `${BASE_URL}${relativeUrl}`;
export const title: string = "Samuel Batista's Inner Circle";
export const description: string = "";

export default function InnerCircle({ posts }) {
  const [searchValue, setSearchValue] = useState("");

  // Sort / filter inner circle blog data
  const filteredBlogPosts = posts
    .sort((a, b) => Number(new Date(b.publishedAt)) - Number(new Date(a.publishedAt)))
    .filter((frontMatter) => frontMatter.title.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <Container>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description
        }}
      />
      <div className="flex flex-col justify-center items-start max-w-2xl mx-auto mb-16">
        <h1 className="font-bold text-3xl md:text-5xl tracking-tight mb-4 text-black dark:text-white">
          Welcome to the Inner Circle
        </h1>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {/* TODO: Better descripition */}
          {/* I am breaking the law by giving you access to some of the material in this blog. If you care about me, you
          must keep it confidential. If I find out you&apos;ve shared information from this blog, you will be banned for
          life. */}
        </p>
        <div className="relative w-full mb-4">
          <input
            aria-label="Search articles"
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search articles"
            className="px-4 py-2 border border-gray-300 dark:border-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
          />
          <svg
            className="absolute right-3 top-3 h-5 w-5 text-gray-400 dark:text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <h3 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">
          All Posts
        </h3>
        {!filteredBlogPosts.length && "No posts found."}
        {filteredBlogPosts.map((frontMatter) => (
          <BlogPost key={frontMatter.title} {...frontMatter} blogUrl="inner-circle" />
        ))}
        {/* <Subscribe /> */}
      </div>
    </Container>
  );
}

export async function getStaticProps() {
  const posts = await getAllFilesFrontMatter("inner-circle");
  return { props: { posts } };
}
