import { useSession } from "@supabase/auth-helpers-react";
import { useTheme } from "next-themes";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Footer from "~/components/Footer";
import { supabase } from "~/lib/supabase";
import { url } from "~/pages/inner-circle";
import { relativeUrl } from "~/pages/work/closed-source";

const linkStyle = (isInWorkPage) =>
  `p-1 sm:p-4 text-gray-900 dark:${isInWorkPage ? "text-gray-900" : "text-gray-100"} underline`;

export default function Container({ children }) {
  const router = useRouter();
  const session = useSession();
  const [mounted, setMounted] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [clickTimeout, setClickTimeout] = useState(-1);
  const { theme, setTheme } = useTheme();
  const isInWorkPage = router.route === relativeUrl;

  // After mounting, we have access to the theme
  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  return (
    mounted && (
      <div className={`bg-white ${isInWorkPage ? "dark:bg-white" : "dark:bg-black"}`}>
        <nav
          className={`sticky-nav flex justify-between items-center max-w-4xl w-full p-8 my-0 md:my-8 mx-auto ${
            isInWorkPage ? "dark:bg-white" : "dark:bg-black"
          } bg-opacity-60`}
        >
          <div>
            <NextLink href="/" className={linkStyle(isInWorkPage)}>
              Home
            </NextLink>
            <NextLink href="/blog" className={linkStyle(isInWorkPage)}>
              Blog
            </NextLink>
            <NextLink href="/work" className={linkStyle(isInWorkPage)}>
              Work
            </NextLink>
            <a href="/resume" target="_blank" className={linkStyle(isInWorkPage)}>
              Resume
            </a>
            {session && (
              <>
                <NextLink href="/inner-circle" className={linkStyle(isInWorkPage)}>
                  Inner Circle
                </NextLink>
                <a
                  href={null}
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    if (isSigningOut) {
                      console.warn("Already signing out");
                      return;
                    }
                    setIsSigningOut(true);
                    try {
                      const expires = new Date(0).toUTCString();
                      document.cookie = `supabase_access_token=; path=/; expires=${expires}; SameSite=Lax; secure`;
                      document.cookie = `supabase_refresh_token=; path=/; expires=${expires}; SameSite=Lax; secure`;

                      // Second sign the user out of supabase on the client
                      const { error: supabaseLogoutError } = await supabase.auth.signOut();

                      if (supabaseLogoutError) {
                        throw supabaseLogoutError;
                      }

                      window.location.reload();
                    } catch (error) {
                      console.trace(error);
                    } finally {
                      setIsSigningOut(false);
                    }
                  }}
                  className={linkStyle(isInWorkPage)}
                >
                  Log Out
                </a>
              </>
            )}
          </div>
          <button
            aria-label="Toggle Dark Mode"
            type="button"
            style={{
              visibility: isInWorkPage ? "hidden" : "visible"
            }}
            disabled={isInWorkPage}
            className="bg-gray-200 dark:bg-gray-800 rounded p-3 h-10 w-10"
            onClick={() => {
              // Easter-egg
              if (clickCount >= 2) {
                window.location.assign(url);
                return;
              } else {
                setTheme(theme === "dark" ? "light" : "dark");

                // Easter-egg
                setClickCount(clickCount + 1);
                if (clickTimeout !== -1) {
                  clearTimeout(clickTimeout);
                }
                setClickTimeout(
                  setTimeout(() => {
                    setClickCount(0);
                  }, 500) as unknown as number
                );
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              stroke="currentColor"
              className="h-4 w-4 text-gray-800 dark:text-gray-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  theme === "dark"
                    ? "M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                    : "M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                }
              />
            </svg>
          </button>
        </nav>
        <main className="flex flex-col justify-center bg-white dark:bg-black px-8">
          {children}
          <Footer />
        </main>
      </div>
    )
  );
}
