export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL ||
  process.env.NEXT_PUBLIC_VERCEL_URL ||
  `http://localhost:${process.env.PORT || 3333}`;

const title = "Samuel Batista's Website";
const description = "Full stack developer with a decade of experience building video game UIs & web applications.";

const SEO = {
  title,
  description,
  canonical: BASE_URL,
  openGraph: {
    type: "website",
    locale: "en_US",
    url: BASE_URL,
    title,
    description,
    images: [
      {
        url: `${BASE_URL}/images/avatar.jpg`,
        alt: "Samuel Batista",
        width: 136,
        height: 136
      }
    ]
  },
  twitter: {
    handle: "@gamedevsam",
    site: "@gamedevsam",
    cardType: "summary_large_image"
  }
};

export default SEO;
