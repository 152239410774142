/* eslint-disable @next/next/no-css-tags */
import { BASE_URL } from "#next-seo.config";
import { NextSeo } from "next-seo";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import Container from "~/components/Container";
import { useWindowSize } from "~/lib/hooks/useWindowSize";

// seo
export const relativeUrl = "/work/closed-source";
export const url = `${BASE_URL}${relativeUrl}`;
export const title = "Samuel Batista's Closed Source Work";
export const description = "Samuel Batista's professional work history (closed source software).";

function toggleExpand(id: string) {
  return function onClick(event) {
    document.getElementById(id).click();
  };
}

export default function WorkClosedSource() {
  const router = useRouter();
  const { width } = useWindowSize();
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description
        }}
      />
      <Head>
        <meta name="description" content="Samuel Batista's work history as a Full Stack Engineer."></meta>
        <link rel="preload" as="style" type="text/css" href="/work/stacks.css" />
        <link rel="preload" as="style" type="text/css" href="/work/primary.css" />
        <link rel="preload" as="style" type="text/css" href="/work/pdf.css" />
      </Head>
      <Container>
        <link rel="stylesheet" type="text/css" href="/work/stacks.css" />
        <link rel="stylesheet" type="text/css" href="/work/primary.css" />
        <link rel="stylesheet" type="text/css" href="/work/pdf.css" />
        <style type="text/css">
          {`
a { text-decoration: underline; }
.description-expander-label { text-decoration: underline; }
.keyboard-console { background-color: black; background-color: rgba(0, 0, 0, .8); position: fixed; left: 100px; bottom: 100px;padding: 10px; text-align: left; border-radius: 6px; z-index: 1000 }.keyboard-console pre { background-color: transparent; color: #ccc; width: auto; height: auto; padding: 0; margin: 0; overflow: visible; line-height:1.5; border: none;}.keyboard-console pre b, .keyboard-console pre a { color: white !important; }.keyboard-console pre kbd { display: inline-block; font-family: monospace; }.keyboard-selected { box-shadow: 15px 15px 50px rgba(0, 0, 0, .2) inset; }
.description-content-full p { color: black; }
.block { font-weight: bold; color: black; }

body {
  padding-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@media only screen and (max-width: 1280px) {
  body {
    min-width: auto;
    width: ${width}px
  }

  main {
    min-width: auto;
    width: ${width}px;
    justify-content: top;
    display: relative;
  }

  .scaleable-wrapper {
    
  }

  .work-page {
  }
}
`}
        </style>
        <div className="scaleable-wrapper">
          <div className="work-page">
            <div className="timeline-wrapper">
              <div className="timeline">
                <header className="header">
                  <div id="form-section-PersonalInfo" className="header-edit-section header-edit-section-1">
                    <div className="display-only">
                      <div className="d-inline-block p12 bg-black-025 bar-lg my24 bs-md">
                        <Image
                          className="d-block bar-sm"
                          src="/images/avatar.webp"
                          alt="avatar"
                          width={136}
                          height={136}
                        />
                      </div>
                      <div className="name">
                        <h4>Samuel Batista</h4>
                      </div>
                      <div className="job has-tooltip">Senior Full Stack Developer</div>

                      <div className="grid jc-center gs16 fc-light fw-wrap">
                        <div className="grid ai-center">
                          <div className="grid--cell mr4">
                            <svg
                              aria-hidden="true"
                              className="svg-icon iconLocation"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                            >
                              <path d="M2 6.38C2 9.91 8.1 17.7 8.1 17.7c.22.29.58.29.8 0 0 0 6.1-7.8 6.1-11.32A6.44 6.44 0 008.5 0 6.44 6.44 0 002 6.38zm9.25.12a2.75 2.75 0 11-5.5 0 2.75 2.75 0 015.5 0z"></path>
                            </svg>
                          </div>
                          <div className="grid--cell wmx2 truncate">
                            <a href="https://www.google.com/maps/place/Baltimore,+MD/" target="__blank">
                              Baltimore, Maryland
                            </a>
                          </div>
                        </div>

                        <div className="grid--cell mr4">
                          <svg
                            aria-hidden="true"
                            className="svg-icon iconLink"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path d="M7.22 11.83a6 6 0 001.62.85l.61-1.8a4.1 4.1 0 114.04-.8l1.26 1.42a6 6 0 10-7.53.33zm3.43-5.6a6 6 0 00-1.6-.87L8.4 7.15a4.1 4.1 0 11-4.05.73L3.12 6.43a6 6 0 107.53-.2z"></path>
                          </svg>
                        </div>
                        <div className="grid--cell">
                          <a href={BASE_URL}>{BASE_URL}</a>
                        </div>

                        <div className="grid--cell mr4">
                          <svg
                            aria-hidden="true"
                            className="svg-icon iconTwitter"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path
                              d="M17 4.04c-.59.26-1.22.44-1.88.52a3.3 3.3 0 001.44-1.82c-.64.37-1.34.64-2.09.79a3.28 3.28 0 00-5.6 2.99A9.3 9.3 0 012.12 3.1a3.28 3.28 0 001.02 4.38 3.28 3.28 0 01-1.49-.4v.03a3.29 3.29 0 002.64 3.22 3.34 3.34 0 01-1.48.06 3.29 3.29 0 003.07 2.28 6.58 6.58 0 01-4.85 1.36 9.33 9.33 0 005.04 1.47c6.04 0 9.34-5 9.34-9.33v-.42a6.63 6.63 0 001.63-1.7L17 4.04z"
                              fill="#2AA3EF"
                            ></path>
                          </svg>
                        </div>
                        <div className="grid--cell">
                          <a href="https://twitter.com/gamedevsam" target="__blank">
                            gamedevsam
                          </a>
                        </div>

                        <div className="grid--cell mr4">
                          <svg
                            aria-hidden="true"
                            className="svg-icon iconGitHub"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <path
                              d="M9 1a8 8 0 00-2.53 15.59c.4.07.55-.17.55-.38l-.01-1.49c-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82a7.42 7.42 0 014 0c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48l-.01 2.2c0 .21.15.46.55.38A8.01 8.01 0 009 1z"
                              fill="#010101"
                            ></path>
                          </svg>
                        </div>
                        <div className="grid--cell">
                          <a href="https://github.com/gamedevsam" target="__blank">
                            gamedevsam
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="form-section-PersonalStatementAndTools"
                    className="header-edit-section header-edit-section-2"
                  >
                    <div className="display-only">
                      <div className="description">
                        <div className="description">
                          <span className="description-content-full">
                            <div id="form-section-PrimarySkills" className="header-edit-section header-edit-section-3">
                              <div className="display-only">
                                <div className="user-technologies">
                                  <label className="block">Expert</label>
                                  <div className="timeline-item-tags">
                                    <span className="post-tag">JavaScript</span>
                                    <span className="post-tag">TypeScript</span>
                                    <span className="post-tag">React</span>
                                    <span className="post-tag">C++</span>
                                    <span className="post-tag">C#</span>
                                    <span className="post-tag">Lua</span>
                                    <span className="post-tag">Haxe</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              id="form-section-SecondarySkills"
                              className="header-edit-section header-edit-section-3"
                            >
                              <div className="display-only">
                                <div className="user-technologies">
                                  <label className="block">Intermediate</label>
                                  <div className="timeline-item-tags">
                                    <span className="post-tag">Node.js</span>
                                    <span className="post-tag">Next.js</span>
                                    <span className="post-tag">Java</span>
                                    <span className="post-tag">Spring</span>
                                    <span className="post-tag">ASP.NET</span>
                                    <span className="post-tag">WordPress</span>
                                    <span className="post-tag">MySQL</span>
                                    <span className="post-tag">AWS</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>

                        <div id="form-section-FavoriteTools" className="header-edit-section header-edit-section-3">
                          <div className="display-only">
                            <div className="user-technologies">
                              <label className="block">Favorite Tools</label>
                              <div className="timeline-item-tags">
                                <span className="post-tag">Visual Studio Code</span>
                                <span className="post-tag">Visual Studio</span>
                                <span className="post-tag">Chrome Debug Tools</span>
                                <span className="post-tag">Git</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="js-featured-assessments"></div>

                <div className="timeline-feed">
                  <div className="timeline-line"></div>

                  <div id="item-10112971" className="timeline-item job" data-order-by="20210910-002-0010112971">
                    <div className="timeline-item-warning">
                      <div className="text ta-center">
                        <h4>Are you sure you want to do that?</h4>

                        <a className="s-btn s-btn__muted s-btn__outlined js-btn-warning">Cancel</a>
                        <a className="s-btn s-btn__filled s-btn__danger js-loader js-btn-confirm">Yes, delete it</a>
                      </div>
                    </div>

                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Jan 2021 → Current <span>(9 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="https://salesforce.com/"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6c1.09 0 2 .91 2 2v2h2c1.09 0 2 .91 2 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div className="img logo align-self js-listcard-hover" data-list-url="https://salesforce.com/">
                        <Image
                          width={56}
                          height={56}
                          src="/work/salesforce-logo.jpg"
                          alt="Salesforce"
                          className="js-list-img"
                          data-list-id="448750"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Senior Software Engineer at{" "}
                          <span className="js-listcard-hover" data-list-url="https://salesforce.com/">
                            <a href="https://salesforce.com/">Salesforce</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags d-flex gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">javascript</span>
                          <span className="grid--cell s-tag">typescript</span>
                          <span className="grid--cell s-tag">lwc</span>
                          <span className="grid--cell s-tag">html</span>
                          <span className="grid--cell s-tag">css</span>
                          <span className="grid--cell s-tag">node.js</span>
                          <span className="grid--cell s-tag">nestjs</span>
                          <span className="grid--cell s-tag">postgresql</span>
                          <span className="grid--cell s-tag">webpack</span>
                          <span className="grid--cell s-tag">docker</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-10112971-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                I&apos;m helping to build a modern publishing experience for AppExchange partners, using
                                Lightning Web Components, JavaScript, HTML, CSS on the Front End and NodeJS, NestJS
                                &amp; Postgres on the Backend. I create high quality, maintainable UI components and use
                                them to build complex, maintainable, high performance, and easy to use web applications.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px;" }}>
                              <p>
                                I&apos;m helping to build a modern publishing experience for AppExchange partners, using
                                Lightning Web Components, JavaScript, HTML, CSS on the Front End and NodeJS, NestJS
                                &amp; Postgres on the Backend. I create high quality, maintainable UI components and use
                                them to build complex, maintainable, high performance, and easy to use web applications.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              htmlFor="item-10112971-collapsible-description"
                              data-less="Less"
                              data-more="Read more"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="item-7142535" className="timeline-item job" data-order-by="20210101-002-0007142535">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Feb 2019 → Jan 2021
                        <span> (1 year, 11 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container">
                        <div
                          className="dno list-card"
                          style={{
                            display: "none",
                            left: "6.38298%",
                            top: "19.8324%"
                          }}
                        >
                          <div className="-avatar">
                            <div className="g-center logo-blank js-logo-blank dno">
                              <svg
                                aria-hidden="true"
                                className="svg-icon iconIndustry"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                              </svg>
                            </div>
                            <div>
                              <Image
                                width={56}
                                height={56}
                                src="/work/EwAiQ.webp"
                                alt="Big Huge Games"
                                className="js-list-img"
                                data-list-id="416638"
                              />
                            </div>
                          </div>

                          <div className="-details">
                            <div className="-name">Big Huge Games</div>
                            <div className="-meta">1 person • company or organization</div>

                            <div className="-users-list">
                              <div className="-user">
                                <div className="avatar -small">
                                  <div className="gravatar-wrapper-26">
                                    <Image src="/images/avatar.webp" alt="" width={26} height={26} className="bar-sm" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <a href="https://bighugegames.com/" className="more">
                              View page
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="https://bighugegames.com/"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div className="img logo align-self js-listcard-hover" data-list-url="https://bighugegames.com/">
                        <Image
                          width={56}
                          height={56}
                          src="/work/EwAiQ.webp"
                          alt="Big Huge Games"
                          className="js-list-img"
                          data-list-id="416638"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Web Tools Engineer at{" "}
                          <span className="js-listcard-hover" data-list-url="https://bighugegames.com/">
                            <a href="https://bighugegames.com/">Big Huge Games</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">TypeScript</span>
                          <span className="grid--cell s-tag">JavaScript</span>
                          <span className="grid--cell s-tag">React</span>
                          <span className="grid--cell s-tag">Mobx</span>
                          <span className="grid--cell s-tag">WebPack</span>
                          <span className="grid--cell s-tag">Node.js</span>
                          <span className="grid--cell s-tag">C#</span>
                          <span className="grid--cell s-tag">ASP.NET</span>
                          <span className="grid--cell s-tag">Java</span>
                          <span className="grid--cell s-tag">Spring</span>
                          <span className="grid--cell s-tag">MySQL</span>
                          <span className="grid--cell s-tag">Amazon Web Services</span>
                          <span className="grid--cell s-tag">Docker</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-7142535-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                I&apos;m building web tools to enable my coworkers to achieve more with less time &amp;
                                effort. Our stack consists of React + TypeScript on the front end, and C#, Java, Spring
                                Framework, MySQL and AWS services on the backend.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                              <p>
                                I&apos;m building web tools to enable my coworkers to achieve more with less time &amp;
                                effort. Our stack consists of React + TypeScript on the front end, and C#, Java, Spring
                                Framework, MySQL and AWS services on the backend.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-7142535-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736915" className="timeline-item job" data-order-by="20190201-002-0005736915">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Apr 2018 → Feb 2019
                        <span> (11 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/392710/lykke?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/392710/lykke?storyType=1"
                      >
                        <Image
                          width={56}
                          height={56}
                          src="/work/ptlwwa1sqygxvqyxpkui.webp"
                          alt="Lykke"
                          className="js-list-img"
                          data-list-id="392710"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Front End Supervisor at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/392710/lykke?storyType=1"
                          >
                            <a href="https://lykke.com/">Lykke</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">TypeScript</span>
                          <span className="grid--cell s-tag">JavaScript</span>
                          <span className="grid--cell s-tag">React</span>
                          <span className="grid--cell s-tag">Mobx</span>
                          <span className="grid--cell s-tag">Node.js</span>
                          <span className="grid--cell s-tag">WebPack</span>
                          <span className="grid--cell s-tag">Docker</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description">
                            <input
                              className="dno description-expander"
                              id="item-5736915-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                I supervised a team of engineers tasked with building a complex web application with
                                React &amp; TypeScript.
                              </p>
                            </span>
                            <span className="description-content-truncated">
                              <p>
                                I supervised a team of engineers tasked with building a complex web application with
                                React &amp; TypeScript.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736915-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736920" className="timeline-item job" data-order-by="20190201-002-0005736920">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Sep 2017 → Feb 2019
                        <span> (1 year, 6 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399715/firaxis-games?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399715/firaxis-games?storyType=1"
                      >
                        <Image
                          width={56}
                          height={56}
                          src="/work/BzgcL.webp"
                          alt="Firaxis Games"
                          className="js-list-img"
                          data-list-id="399715"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Senior UI Programmer at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/399715/firaxis-games?storyType=1"
                          >
                            <a href="https://firaxis.com/">Firaxis Games</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">C++</span>
                          <span className="grid--cell s-tag">Lua</span>
                          <span className="grid--cell s-tag">Visual Studio</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description">
                            <input
                              className="dno description-expander"
                              id="item-5736920-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                I developed user interfaces for big budget video games. Games I worked on as a Senior UI
                                Programmer: Sid Meier&apos;s Civilization VI: Rise &amp; Fall.
                              </p>
                            </span>
                            <span className="description-content-truncated">
                              <p>
                                I developed user interfaces for big budget video games. Games I worked on as a Senior UI
                                Programmer: Sid Meier&apos;s Civilization VI: Rise &amp; Fall.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736920-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736892" className="timeline-item job" data-order-by="20180401-002-0005736892">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Aug 2017 → Apr 2018
                        <span> (9 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/392710/lykke?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/392710/lykke?storyType=1"
                      >
                        <Image
                          width={56}
                          height={56}
                          src="/work/ptlwwa1sqygxvqyxpkui.webp"
                          alt="Lykke"
                          className="js-list-img"
                          data-list-id="392710"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Front End Programmer at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/392710/lykke?storyType=1"
                          >
                            <a href="https://lykke.com/">Lykke</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">TypeScript</span>
                          <span className="grid--cell s-tag">JavaScript</span>
                          <span className="grid--cell s-tag">React</span>
                          <span className="grid--cell s-tag">Mobx</span>
                          <span className="grid--cell s-tag">Node.js</span>
                          <span className="grid--cell s-tag">WebPack</span>
                          <span className="grid--cell s-tag">Docker</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-5736892-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                I worked on a complex web application with React &amp; TypeScript for a Swiss FinTech
                                company building a global marketplace on the blockchain. This opportunity allowed me to
                                learn new technologies and skills in a competitive startup environment.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                              <p>
                                I worked on a complex web application with React &amp; TypeScript for a Swiss FinTech
                                company building a global marketplace on the blockchain. This opportunity allowed me to
                                learn new technologies and skills in a competitive startup environment.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736892-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5723479" className="timeline-item job" data-order-by="20170901-002-0005723479">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        May 2011 → Sep 2017
                        <span> (6 years, 5 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399715/firaxis-games?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399715/firaxis-games?storyType=1"
                      >
                        <Image
                          width={56}
                          height={56}
                          src="/work/BzgcL.webp"
                          alt="Firaxis Games"
                          className="js-list-img"
                          data-list-id="399715"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          UI Programmer at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/399715/firaxis-games?storyType=1"
                          >
                            <a href="https://firaxis.com/">Firaxis Games</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">C++</span>
                          <span className="grid--cell s-tag">ActionScript</span>
                          <span className="grid--cell s-tag">UnrealScript</span>
                          <span className="grid--cell s-tag">UE3</span>
                          <span className="grid--cell s-tag">Visual Studio</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-5723479-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                I developed user interfaces for big budget video games. Games I worked on as a UI
                                Programmer: XCOM: Enemy Unknown, XCOM: Enemy Within, XCOM 2 and Sid Meier&apos;s
                                Civilization VI.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                              <p>
                                I developed user interfaces for big budget video games. Games I worked on as a UI
                                Programmer: XCOM: Enemy Unknown, XCOM: Enemy Within, XCOM 2 and Sid Meier&apos;s
                                Civilization VI.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5723479-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736884" className="timeline-item job" data-order-by="20151201-002-0005736884">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Sep 2015 → Dec 2015
                        <span> (4 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399880/storj?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399880/storj?storyType=1"
                      >
                        <Image
                          width={64}
                          height={30}
                          src="/work/vAO4Z.webp"
                          alt="Storj"
                          className="js-list-img"
                          data-list-id="399880"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Front End Programmer at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/399880/storj?storyType=1"
                          >
                            <a href="https://storj.io/">Storj</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">JavaScript</span>
                          <span className="grid--cell s-tag">jQuery</span>
                          <span className="grid--cell s-tag">Electron</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <span className="description-content-expanded" style={{ maxHeight: "65px" }}>
                              <p>
                                I helped develop the user interface for Storj Share, an{" "}
                                <a
                                  href="https://github.com/storj/storjshare-gui"
                                  rel="nofollow noreferrer"
                                  target="__blank"
                                >
                                  Electron based GUI for Storj
                                </a>
                                , an open source and decentralized data storage application.
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736853" className="timeline-item job" data-order-by="20110501-002-0005736853">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        May 2010 → May 2011
                        <span> (1 year, 1 month)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399878/timegate-studios?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399878/timegate-studios?storyType=1"
                      >
                        <Image
                          width={64}
                          height={30}
                          src="/work/33UzW.webp"
                          alt="TimeGate Studios"
                          className="js-list-img"
                          data-list-id="399878"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          UI Programmer at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/399878/timegate-studios?storyType=1"
                          >
                            <a href="https://en.wikipedia.org/wiki/TimeGate_Studios">TimeGate Studios</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">UnrealScript</span>
                          <span className="grid--cell s-tag">UE3</span>
                          <span className="grid--cell s-tag">Flash CS5</span>
                          <span className="grid--cell s-tag">ActionScript</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-5736853-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                Programmed several screens for Section 8: Prejudice using Flash and Actionscript.
                                Extended and repaired the functionality of Unreal Engine&apos;s Online Subsystems
                                (hosting, searching, inviting and joining games) for PS3, Xbox 360 and PC.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                              <p>
                                Programmed several screens for Section 8: Prejudice using Flash and Actionscript.
                                Extended and repaired the functionality of Unreal Engine&apos;s Online Subsystems
                                (hosting, searching, inviting and joining games) for PS3, Xbox 360 and PC.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736853-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736847" className="timeline-item job" data-order-by="20100501-002-0005736847">
                    <header>
                      <span className="timeline-item-type">Position</span>

                      <span className="timeline-item-date">
                        Mar 2010 → May 2010
                        <span> (3 months)</span>
                      </span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="js-listcard-container"></div>
                      <div
                        className="g-center img logo logo-blank js-logo-blank dno align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399879/sim-ops-studios?storyType=1"
                      >
                        <svg
                          aria-hidden="true"
                          className="svg-icon iconIndustry"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M10 16v-4H8v4H2V4c0-1.1.9-2 2-2h6a2 2 0 012 2v2h2a2 2 0 012 2v8h-6zM4 4v2h2V4H4zm0 4v2h2V8H4zm4-4v2h2V4H8zm0 4v2h2V8H8zm-4 4v2h2v-2H4zm8 0v2h2v-2h-2zm0-4v2h2V8h-2z"></path>
                        </svg>
                      </div>

                      <div
                        className="img logo align-self js-listcard-hover"
                        data-list-url="/users/story/lists/399879/sim-ops-studios?storyType=1"
                      >
                        <Image
                          width={56}
                          height={56}
                          src="/work/LmWqA.webp"
                          alt="Sim Ops Studios"
                          className="js-list-img"
                          data-list-id="399879"
                        />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          Web Tools Programmer at{" "}
                          <span
                            className="js-listcard-hover"
                            data-list-url="/users/story/lists/399879/sim-ops-studios?storyType=1"
                          >
                            <a href="https://www.linkedin.com/company/sim-ops-studios/">Sim Ops Studios</a>
                          </span>
                        </div>
                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">Lua</span>
                          <span className="grid--cell s-tag">Flash</span>
                          <span className="grid--cell s-tag">ActionScript</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description">
                            <input
                              className="dno description-expander"
                              id="item-5736847-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                Wrote Collada importer for WildPockets game engine. Extended functionality of the web
                                based game engine&apos;s tools using Lua.
                              </p>
                            </span>
                            <span className="description-content-truncated">
                              <p>
                                Wrote Collada importer for WildPockets game engine. Extended functionality of the web
                                based game engine&apos;s tools using Lua.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736847-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}

                  <div id="item-5736827" className="timeline-item education" data-order-by="20091101-000-0005736827">
                    <header>
                      <span className="timeline-item-type">Education</span>

                      <span className="timeline-item-date">Dec 2007 → Nov 2009 (1 year, 11 months)</span>
                    </header>

                    <div className="timeline-item-content with-description">
                      <div className="img logo">
                        <Image width={106} height={74} src="/work/7SK2Q.webp" alt="BS Game Development" />
                      </div>

                      <div className="timeline-item-text">
                        <div className="timeline-item-title">
                          <a href="https://www.fullsail.edu/" rel="nofollow">
                            BS Game Development, Full Sail University
                          </a>
                        </div>

                        <div className="timeline-item-tags grid gs4 fl-none fw-wrap mt16">
                          <span className="grid--cell s-tag">C++</span>
                          <span className="grid--cell s-tag">C#</span>
                          <span className="grid--cell s-tag">Lua</span>
                        </div>

                        <div className="timeline-item-paragraph">
                          <div className="description description-ellipsis">
                            <input
                              className="dno description-expander"
                              id="item-5736827-collapsible-description"
                              type="checkbox"
                            />
                            <span className="description-content-full">
                              <p>
                                Full Sail is a great school for people wanting to get into the video game industry. I
                                learned everything from tools and gameplay programming to advanced topics such as ui,
                                animation, sound and graphics.
                              </p>
                            </span>
                            <span className="description-content-truncated" style={{ maxHeight: "65px" }}>
                              <p>
                                Full Sail is a great school for people wanting to get into the video game industry. I
                                learned everything from tools and gameplay programming to advanced topics such as ui,
                                animation, sound and graphics.
                              </p>
                            </span>
                            <label
                              className="description-expander-label"
                              data-less="Less"
                              data-more="Read more"
                              onClick={toggleExpand("item-5736827-collapsible-description")}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <-- /timeline-item --> */}
                </div>
              </div>
            </div>
            {/* 			<script>
          StackExchange.ready(function() {
            if (StackExchange.user) {
              StackExchange.user.initTimeline();
              initTagRenderer("".split(" "), "".split(" "));
            }
          })
        </script>
          </div> */}

            <div className="timeline-print show-for-print">
              <div className="wrapper">
                <div className="col-full header">
                  <div className="name">
                    <h1>Samuel Batista</h1>
                  </div>
                  <div className="personal-info" toggle-section="personal" toggle-section-title="Personal Info">
                    <span className="address" toggle-id="location" toggle-description="Address">
                      Baltimore, Maryland
                    </span>{" "}
                    <span className="email" toggle-id="email" toggle-description="Email">
                      <a href="mailto:sambatista@pm.me">sambatista@pm.me</a>
                    </span>{" "}
                    <span className="website" toggle-id="website" toggle-description="Web Site Url">
                      <a href={BASE_URL}>{BASE_URL}</a>
                    </span>{" "}
                    <span className="github" toggle-id="github" toggle-description="Github">
                      <a href="http://github.com/gamedevsam">http://github.com/gamedevsam</a>
                    </span>{" "}
                  </div>
                </div>

                <div className="col-full statement" toggle-section="personal" toggle-section-title="Personal Info">
                  <blockquote toggle-id="personal-statement" toggle-description="Personal Statement">
                    <p>
                      JavaScript specialist with a background in UI programming for video games and web applications.
                    </p>

                    <p>
                      Primary skills: JavaScript / TypeScript, React, C++, C#, Lua, Flash / ActionScript (Scaleform)
                    </p>

                    <p>Secondary skills: Node.js, Java, Spring, ASP.NET, WordPress, MySQL, Docker, AWS, Snowflake</p>
                  </blockquote>
                </div>

                <div className="col-full skills" toggle-section="technical" toggle-section-title="Technical Skills">
                  <div className="col-left">
                    <h2>Technical Skills</h2>
                  </div>
                  <div className="col-right">
                    <table>
                      <tbody>
                        <tr toggle-id="likes" toggle-description="Likes">
                          <td className="tags">Likes:</td>
                          <td>TypeScript JavaScript React C++ C# Lua haxe</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-full">
                  <div className="col-left">
                    <h2>Experience</h2>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Feb 2019 → Current</em>
                      </span>
                      <strong>Web Tools Engineer</strong>–{" "}
                      <a href="https://bighugegames.com/" className="title">
                        Big Huge Games
                      </a>
                      <div>
                        TypeScript, JavaScript, React, Mobx, WebPack, Node.js, C#, ASP.NET, Java, Spring, MySQL, Amazon
                        Web Services, Docker
                      </div>
                    </div>
                    <div className="description">
                      <p>
                        I&apos;m building web tools to enable my coworkers to achieve more with less time &amp; effort.
                        Our stack consists of React + TypeScript on the front end, and C#, Java, Spring Framework, MySQL
                        and AWS services on the backend.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Apr 2018 → Feb 2019</em>
                      </span>
                      <strong>Front End Supervisor</strong>–{" "}
                      <a href="http://www.lykke.com/" className="title">
                        Lykke
                      </a>
                      <div>TypeScript, JavaScript, React, Mobx, Node.js, WebPack, Docker</div>
                    </div>
                    <div className="description">
                      <p>
                        I supervised a team of engineers tasked with building a complex web application with React &amp;
                        TypeScript.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Sep 2017 → Feb 2019</em>
                      </span>
                      <strong>Senior UI Programmer</strong>–{" "}
                      <a href="https://firaxis.com/" className="title">
                        Firaxis Games
                      </a>
                      <div>C++, Lua, Visual Studio</div>
                    </div>
                    <div className="description">
                      <p>
                        I developed user interfaces for big budget video games. Games I worked on as a Senior UI
                        Programmer: Sid Meier&apos;s Civilization VI: Rise &amp; Fall.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Aug 2017 → Apr 2018</em>
                      </span>
                      <strong>Front End Programmer</strong>–{" "}
                      <a href="http://www.lykke.com/" className="title">
                        Lykke
                      </a>
                      <div>TypeScript, JavaScript, React, Mobx, Node.js, WebPack, Docker</div>
                    </div>
                    <div className="description">
                      <p>
                        I worked on a complex web application with React &amp; TypeScript for a Swiss FinTech company
                        building a global marketplace on the blockchain. This opportunity allowed me to learn new
                        technologies and skills in a competitive startup environment.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>May 2011 → Sep 2017</em>
                      </span>
                      <strong>UI Programmer</strong>–{" "}
                      <a href="https://firaxis.com/" className="title">
                        Firaxis Games
                      </a>
                      <div>C++, ActionScript, UnrealScript, UE3, Visual Studio</div>
                    </div>
                    <div className="description">
                      <p>
                        I developed user interfaces for big budget video games. Games I worked on as a UI Programmer:
                        XCOM: Enemy Unknown, XCOM: Enemy Within, XCOM 2 and Sid Meier&apos;s Civilization VI.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Sep 2015 → Dec 2015</em>
                      </span>
                      <strong>Front End Programmer</strong>–{" "}
                      <a href="https://storj.io/share.html" className="title">
                        Storj
                      </a>
                      <div>JavaScript, jQuery, Electron</div>
                    </div>
                    <div className="description">
                      <p>
                        I helped develop the user interface for Storj Share, an{" "}
                        <a href="https://github.com/storj/storjshare-gui" rel="nofollow noreferrer">
                          Electron based GUI for Storj
                        </a>
                        , an open source and decentralized data storage application.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>May 2010 → May 2011</em>
                      </span>
                      <strong>UI Programmer</strong>–{" "}
                      <a href="https://www.linkedin.com/company/timegate-studios/" className="title">
                        TimeGate Studios
                      </a>
                      <div>UnrealScript, UE3, Flash CS5, ActionScript</div>
                    </div>
                    <div className="description">
                      <p>
                        Programmed several screens for Section 8: Prejudice using Flash and Actionscript. Extended and
                        repaired the functionality of Unreal Engine&apos;s Online Subsystems (hosting, searching,
                        inviting and joining games) for PS3, Xbox 360 and PC.
                      </p>
                    </div>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Mar 2010 → May 2010</em>
                      </span>
                      <strong>Web Tools Programmer</strong>–{" "}
                      <a href="https://www.linkedin.com/company/sim-ops-studios/" className="title">
                        Sim Ops Studios
                      </a>
                      <div>Lua, Flash, ActionScript</div>
                    </div>
                    <div className="description">
                      <p>
                        Wrote Collada importer for WildPockets game engine. Extended functionality of the web based game
                        engine&apos;s tools using Lua.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-full">
                  <div className="col-left">
                    <h2>Education</h2>
                  </div>
                  <div className="col-right avoid-break">
                    <div className="meta">
                      <span className="dates fr">
                        <em>Dec 2007 → Nov 2009</em>
                      </span>
                      <strong>BS Game Development</strong>– <span className="title">Full Sail University</span>
                      <div>C++, C#, Lua</div>
                    </div>
                    <div className="description">
                      <p>
                        Full Sail is a great school for people wanting to get into the video game industry. I learned
                        everything from tools and gameplay programming to advanced topics such as ui, animation, sound
                        and graphics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
