import Link from "next/link";
import styles from "../styles/Footer.module.scss";

const ExternalLink = ({ href, children }) => (
  <a className="text-sm text-gray-500 transition" target="_blank" rel="noopener noreferrer" href={href}>
    {children}
  </a>
);

const InternalLinks = () => (
  <div className="space-x-3 text-sm">
    <Link href="/">/samuel-batista</Link>
    <Link href="/blog">/blog</Link>
    <Link href="/work">/work</Link>
    <Link href="/resume" target="_blank">
      /resume
    </Link>
  </div>
);

const SocialLinks = () => (
  <div className="flex space-x-4 mt-4">
    <div className={styles["social-links-container"]}>
      <ExternalLink href="https://twitter.com/gamedevsam">
        <span className="sr-only">Twitter</span>
        <svg className="h-5 w-5" viewBox="0 0 24 24">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
          </g>
        </svg>
      </ExternalLink>
      <ExternalLink href="https://github.com/gamedevsam">
        <span className="sr-only">Github</span>
        <svg className="h-5 w-5" viewBox="0 0 24 24">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
          </g>
        </svg>
      </ExternalLink>
      <ExternalLink href="https://www.linkedin.com/in/sambatista">
        <span className="sr-only">LinkedIn</span>
        <svg className="h-5 w-5" viewBox="0 0 24 24">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
            <rect x="2" y="9" width="4" height="12" />
            <circle cx="4" cy="4" r="2" />
          </g>
        </svg>
      </ExternalLink>
      <ExternalLink href="https://www.youtube.com/c/SamuelBatista/">
        <span className="sr-only">YouTube</span>
        <svg className="h-5 w-5" viewBox="0 0 24 24">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22.54 6.42a2.78 2.78 0 00-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 00-1.94 2A29 29 0 001 11.75a29 29 0 00.46 5.33A2.78 2.78 0 003.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 001.94-2 29 29 0 00.46-5.25 29 29 0 00-.46-5.33z" />
            <path d="M9.75 15.02l5.75-3.27-5.75-3.27v6.54z" />
          </g>
        </svg>
      </ExternalLink>
    </div>
    <div className={styles["inner-circle-container"]}>
      <Link href="/inner-circle">
        <div className={styles["inner-circle-outline"]}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={styles["inner-circle"]}>
            {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
            <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
          </svg>
        </div>
      </Link>
    </div>
  </div>
);

export default function Footer() {
  return (
    <footer className="flex flex-col items-center mb-8">
      <InternalLinks />
      <SocialLinks />
      <div className="flex flex-col items-center">
        <a
          className="text-sm pt-4 text-gray-500"
          href="https://github.com/leerob/leerob.io"
          rel="noreferrer"
          target="_blank"
          title="leerob.io"
        >
          Forked from leerob.io
        </a>
        <a
          className="text-sm pt-4 text-gray-500"
          href="https://nextjs.org/"
          rel="noreferrer"
          target="_blank"
          title="NextJS"
        >
          Made with NextJS
        </a>
      </div>
    </footer>
  );
}
