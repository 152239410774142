import format from "comma-number";
import Link from "next/link";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";

const BlogPost = ({ title, summary, slug, blogUrl = "blog", views = null, session = null }) => {
  const { data } = useSWR(
    views == null ? [slug ? `/api/${blogUrl}/${blogUrl}/${slug}` : null, session] : null,
    fetcher
  );
  views = data?.total ?? views;

  return (
    <div className="mb-8 w-full">
      <div className="flex flex-col md:flex-row justify-between">
        <h4 className="underline text-lg md:text-xl font-medium mb-2 w-full text-gray-900 dark:text-gray-100">
          <Link href={`/${blogUrl}/${slug}`} className="no-underline w-full">
            {title}
          </Link>
        </h4>
        <p className="text-gray-500 text-left md:text-right w-32 mb-4 md:mb-0">
          {`${views != null ? format(views) : "–––"} views`}
        </p>
      </div>
      <p className="text-gray-600 dark:text-gray-400">{summary}</p>
    </div>
  );
};

export default BlogPost;
